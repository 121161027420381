<template>
  <div class="jobhunter">
    <div class="top" v-if="indexshow">
      <div class="img">
        <img src="../../assets/qiuzhizhaopin.png" />
        <!-- <img :src="list.PhotoUrl" /> -->
      </div>
      <div class="name">{{list.Name}}</div>
    </div>
    <div v-if="indexshow">
      <!-- <van-cell title="在线简历" is-link />
      <van-cell title="投递列表" is-link /> -->
      <div class="center">
        <van-cell title="在线简历" to="/list/jobhunter/resume" is-link />
        <van-cell title="职位推荐" to="/list" is-link />
        <van-cell title="投递列表" to="/list/jobhunter/send" is-link />
        <van-cell title="修改密码" @click="change" is-link />
        <van-cell title="退出登录" @click="quit" is-link />
      </div>
    </div>
    <div v-if="passwordshow">
      <div style="width:95%;margin:10px 2.5%">
        <van-field v-model="ChangePwd.opwd" required label="旧密码" />
      <van-field v-model="ChangePwd.npwd" required type="password" label="新密码" />
      <van-field v-model="ChangePwd.npwds" required type="password" label="重复密码" />
      </div>
      <van-button @click="changePassword " round type="info" style="width: 80%;margin: 15px 10%;">提 交</van-button>
      <van-button @click="changes " round style="width: 80%;margin: 0px 10%;">返 回</van-button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { Toast } from 'vant';
Vue.use(Toast);
import { getToken, setToken,removeToken } from "@/utils/auth";
import { paasswords,JS_GetMyResume } from "@/api/ResumeJS";
export default {
  data(){
    return{
      ChangePwd:{
        opwd:"", //旧密码、
        npwd:"", //新密码
        npwds:"",
      },
      indexshow:true,
      passwordshow:false,
      list:[],
    }
  },
  created(){
    var token = getToken();
    if(token == undefined){
      this.$router.push({
        name:'recruitment' ,
      })
    };
    this.LoginSeeker();
  },
  methods:{
    LoginSeeker(){  //获取当前登录用户
      JS_GetMyResume({accToken:getToken()}).then((res)=>{
        console.log(res);
        this.list = res.data.data;
      })
    },
    quit(){  //退出登录
      removeToken();
      this.$router.push({
        name:'list' ,
      })
    },
    change(){ //修改密码显示
      this.indexshow  = false;
      this.passwordshow  = true;
    },
    changes(){
      this.indexshow  = true;
      this.passwordshow  = false;
    },
    changePassword(){ //修改密码
    if(this.ChangePwd.opwd == ""){
      Toast.fail('旧密码不能为空!');
      return false;
    }
    if(this.ChangePwd.npwd == ""){
      Toast.fail('新密码不能为空!');
      return false;
    }
    if(this.ChangePwd.npwds == ""){
      Toast.fail('请再次输入密码!');
      return false;
    }
    if(this.ChangePwd.npwd != this.ChangePwd.npwds){
      Toast.fail('两次密码不同!');
      return false;
    }
    this.ChangePwd.accToken = getToken();
      paasswords(this.ChangePwd).then((res)=>{
         console.log(res);
        if(res.data.code == 0){
          Toast.success('修改成功!');
          removeToken();
          this.$router.push({
        name:'recruitment' ,
      })
        }else {
          Toast.fail(res.data.msg);
        }
      })
    },
  }
}
</script>
<style>
.jobhunter {
  background: rgb(245, 242, 242);
  height: 100%;
}
.jobhunter .top {
  position: relative;
  width: 100%;
  height: 200px;
  background: #1b80ec;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}
.jobhunter .center{
  width: 85%;
  margin: 10px auto;
  background: #fff;
  border-radius: 15px;
  padding: 15px;
}
/* .jobhunter .center div {
  display: flex;
  justify-content:space-between;
  align-items: center;
  margin: 10px 0px;
  background: #fff;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
} */
.top .img {
  z-index: 99;
  position: absolute;
  width: 100%;
  top: 30px;
  text-align: center;
}
.top .img img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
.top .name {
  position: absolute;
  bottom: 35px;
  width: 100%;
  font-size: 22px;
  color: #fff;
  text-align: center;
}
</style>